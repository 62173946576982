/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "스탠다드아웃 74번째 로그에서는 연이은 컨퍼런스 취소, MySQL 버전 업 그리고 한국에서 시작된 유명한 오픈소스 등에 대해 이야기를 나눴습니다."), "\n", React.createElement(_components.h3, null, "코로나19로 전세계 컨퍼런스 취소 혹은 연기중"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/news--it-conferences-cancled-because-of-covid-19"
  }, "코로나 19 바이러스 여파로 전세계 IT 컨퍼런스 줄지어 취소나 연기 | 44BITS 기술 블로그")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=Xr4BRuGSMLk&list=PLVKMvBGg2tCcKAn4B6sJS8iRsRVrcW1ZJ"
  }, "재택근무와 업무 생산성 그리고 AWS 신규 기능 요약 :: 차니의 클라우드 클리닉 1회 - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://hashidays.com/"
  }, "HashiDays Sydney")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://hashiconf.com/"
  }, "HashiConf")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/King_County,_Washington"
  }, "King County, Washington - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ja.dict.naver.com/#/entry/jako/380a229dbb94427d995a7e3cf124db4d"
  }, "'かいぎ': 네이버 일본어사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://newspeppermint.com/2020/02/20/corona19flu/"
  }, "코로나바이러스를 독감과 비교하지 맙시다.")), "\n"), "\n", React.createElement(_components.h3, null, "MySQL 8.0 릴리스"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://dev.mysql.com/doc/relnotes/mysql/8.0/en/"
  }, "MySQL :: MySQL 8.0 Release Notes")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.vaultproject.io/docs/internals/rotation/"
  }, "Key Rotation | Vault by HashiCorp")), "\n"), "\n", React.createElement(_components.h3, null, "한국에서 시작된 1만 스타 이상 오픈소스"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://byline.network/2020/03/3-63/"
  }, "깃허브에서 1만 스타 받은 한국의 오픈소스들 - Byline Network")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/junegunn/fzf"
  }, "junegunn/fzf: A command-line fuzzy finder")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/peco/peco"
  }, "peco/peco: Simplistic interactive filtering tool")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/junegunn"
  }, "junegunn (Junegunn Choi)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://pytorch.org/"
  }, "PyTorch")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/nhn/tui.editor"
  }, "nhn/tui.editor: 🍞📝 Markdown WYSIWYG Editor. GFM Standard + Chart & UML Extensible.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/nhn/tui.chart"
  }, "nhn/tui.chart: 🍞📊 Beautiful chart for data visualization.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://meetup.toast.com/"
  }, "TOAST Meetup")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/posquit0/Awesome-CV"
  }, "posquit0/Awesome-CV: Awesome CV is LaTeX template for your outstanding job application")), "\n"), "\n", React.createElement(_components.h3, null, "로빈후드 연속 장애"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.cnbc.com/2019/07/22/robinhood-lands-a-7point6-billion-valuation-after-recent-funding-round.html"
  }, "Robinhood lands a $7.6 billion valuation after recent funding round")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://status.robinhood.com/"
  }, "Robinhood Status")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://fortune.com/2020/03/03/robinhood-down-outage-second-day/"
  }, "Robinhood stock app down for second day in a row, after Fed cuts interest rates | Fortune")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://stdout.fm/1/"
  }, "stdout_001.log: 루비 25주년, Github 장애 | 개발자 팟캐스트 stdout.fm")), "\n"), "\n", React.createElement(_components.h3, null, "너굴님 이직 소식"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.ondemandkorea.com/"
  }, "OnDemandKorea")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://vreview.tv/"
  }, "브이리뷰")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.daangn.com/"
  }, "당근마켓 - 우리 동네 중고거래")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
